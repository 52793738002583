
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

/*
 * @file
 * global.styles
 */

html {
  background-color:$body_background;
}

body {
  color: $text_color;
  background:transparent;
}

/* =============================================================================
 *   Wrappers
 * ========================================================================== */

.header-top-bar{
  background:white;
  height:65px;
  > .inner{
    margin:0 auto;
    max-width:$page_max_width + ($page_outer_grid * 2);
  }
}

.page{
  @if($full_width_page == false){
    background:$page_background;
    margin:0 auto;
  }
}

.page-header {
  background-color:$page_background;
  min-height:190px; // prevent the absolute positioned elements from overlaying each other
  @include media-breakpoint-down(md){
    display:none; // hide while the headroom bar is active
  }
  > .inner{
    clear:both;
  }
}
.block-panels-mini-header-map{
  .panel-pane{
    margin-bottom:0;
  }
}

.page-columns {}

.page-content-column {}

.page-content {
  // temporary flex box bugfix - otherwise overwidth children, like slideshows, stretching the container to its width.
  // overflow:hidden;
}

.page-footer {
  @include bold-cutline(top);
  color:$grey;
  a{
    color:$grey;
    &:hover{
      color:$text_color;
    }
  }
  .block-title{
    color:$text_color;
    font-family:$font_first;
    font-weight:$font_weight_bold;
    a{
      color:$text_color;
    }
  }
  .block:not(:last-child){
    @include cutline(bottom);
    @include media-breakpoint-up(md){
      @include cutline(right, $half_grid, 0);
    }
  }
}

/* =============================================================================
 *   Branding
 * ========================================================================== */

/*
 * Wraps all the branding elements, logo, name and slogan
 */
.branding {
  @media #{$medium-up}{
    float:left;
    width:35%;
  }
}

/*
 * Logo
 */
.page-logo {
  padding: 0;
  margin-top:-8px;
  text-align:center;
  @media #{$large-up}{
    text-align:left;
    margin-bottom:-50px;
    position:relative;
    z-index:5;
    padding:8px $thin_grid 5px;
    background-color:white;
    box-shadow:0 9px 3px -5px $shadow_intensity;
  }
  a{
    display:block;
  }
  img {
    vertical-align: bottom;
  }
}

/*
 * Site name (h1)
 */
.page-name {
  margin: 0;
}

/*
 * Site slogan (h2)
 */
.page-slogan {
  margin: 0;
}

/* =============================================================================
 *   Animations (CSS)
 * ========================================================================== */

// Always fade colors
*,
*::before,
*::after{
  transition-property: color, background-color, border-color, transform;
  transition-duration: 300ms;
}

// Pulsating Glow
@keyframes pulsating-glow {
  0% { box-shadow:0 0 3px $red; }
  50% { box-shadow:0 0 10px $red; }
  100% { box-shadow:0 0 3px $red; }
}

/* Dragging complete (eg. Sticky Tables) */
@keyframes dragging-complete {
  0%   { background:#f5ffea; }
  100% { background:#fff; }
}

/* Swipe In (eg. mobile menu trail) */
@keyframes swipe-in {
  0%   {
    transform:translate(-150px, 0);
  }
  100% {
    transform:translate(0, 0);
  }
}


/* Parallax Scrolling */
// further information inside the layout.custom.js
// todo: we should move this parallax shit into the lib scripts

$parallaxContainerDefaultHeight: 600px;

.parallax-container{
  overflow:hidden;
  height:$parallaxContainerDefaultHeight;
  position:relative;
  &--full-height{

  }
}
.parallax{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  .parallax-image-container{ // default script uses the img, but we need to move the info icon also .. so we use this custom container
    // display: none;
    position: absolute;
    left: 50%; // origin 50%
    bottom: 0;
    // min-width: 100%; origin
    max-width:100%;
    // min-height: 100%; origin
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateX(-50%); // origin: -50%
    transform: translateX(-50%); // origin: -50%
  }
  img{

  }
}



/* =============================================================================
 *   Regions
 * ========================================================================== */

.region {}

.region-inner {}

.region-header-top-bar{
  padding-top:8px;
  padding-bottom:8px;
  > .region-inner{
    display: flex;
    justify-content: flex-end;
  }
  @media #{$medium-up}{
    float:left;
    width:65%;
  }
  .block{
    margin-left:$thin_grid;
  }
  // Search block
  .block-search-form{
    max-width:445px;
    width:100%;
    float:right;
  }
}

.region-header {
  .menu{
    @include horizontal-menu();
    @include tiny-menu('horizontal');
  }
}

.region-header-slide{
  clear:both;
  text-align:center; // center slide image
}

.navigation-bar-wrapper{
  clear:both;
  margin-bottom:10px;
  margin-left:$page_outer_grid * -1;
  margin-right:$page_outer_grid * -1;
  @include clearfix-after;
  > .inner{
    @media #{$large-up}{
      float:left;
      position:relative;
      z-index:99999; // Leaflet has a very high z-index ..
      height:54px;
      margin-top:-54px;
      background: linear-gradient(to bottom, $white_glass_light 0%, white 100%);
      padding:4px 4px 0 4px;
      border-radius:$border_radius $border_radius 0 0;
    }
  }
}

.region-help {
  .block{
    @include message('info');
  }
}

.sidebar-wrapper{
  // Blocks
  .block{
    margin-bottom:$grid_vertical;
  }
  .block-title{
    border-bottom:1px solid $grey;
    padding-bottom:4px;
    margin-bottom:$grid_vertical / 2;
  }
  // Filter Styling

  // Sidebar Menus
  .menu{
    li{
      a{
        padding-left:0;
        padding-right:0;
        &.active{
          color:$highlight;
          font-weight:$font_weight_bold;
        }
      }
      &.depth-1{
        &.expandable-menu-item{
          &.open{
            > a{
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
              margin-right:45px;
              font-weight:$font_weight_bold;
            }
          }
        }
        > .btn-expandable-menu-item--closer{
          width:40px;
          height:41px;
          background:$grey_light;
          padding:3px;
          border-radius:$border_radius_small $border_radius_small 0 0;
          top:0;
          &::before{
            left:7px;
            top:7px;
            height:25px;
            width:25px;
            border-radius:50%;
            background: linear-gradient(to bottom, $highlight 0%, $highlight_dark 100%);
          }
        }
      }
    }
    .menu{
      // Layer 2 and following
      background:$grey_light;
      padding:$thin_grid;
      border-radius:$border_radius_small 0 $border_radius_small $border_radius_small;
    }
  }
}
// Calendar Widget
@import "components/_calendar_widget";

.first-sidebar-wrapper{
  border-right:8px solid $grey_light;
}
.second-sidebar-wrapper{
  border-left:8px solid $grey_light;
}

.region-sidebar-first {}

.region-sidebar-second {}

.region-footer {
  .menu{
    @if($show_footer_menu_vertical == false){
      @include tiny-menu('horizontal');
    }@else{
      li{
        a{
          // make the links fit the grid lines
          padding-top:8px;
          padding-bottom:8px;
          padding-left:0;
          padding-right:0;
        }
      }
    }
  }
}

/* =============================================================================
 *   Formatter Classes
 * ========================================================================== */

.text-center{
  text-align:center;
  .block-title{
    text-align:left;
  }
}


/* =============================================================================
 *   Block Styling
 * ========================================================================== */

.block {

}
.block-inner {

}
.block-title {
  margin-top: 0;
}
.block-content {
  p:last-child{ margin-bottom:0; }
}

/* Block Specific */
/* -- Search */
.region-header .block-search{
  margin-bottom:0;
  margin-top:15px;
  @if($headroom_searchblock == true){
    display:none;
  }
  @media #{$medium-up}{
    float:right;
    @if($headroom_searchblock == true){
      display:block;
    }
  }
}

/* -- Header Image */
.block-views-header-slideshow-block{
  line-height:0;
  display:none;
  @media #{$medium-up}{
    display:block;
  }
}
.view-header-slideshow{
  position:relative;
  div[class*="item-"]{
    // non js classes! the hide thing beyond may be deprecated (with .owl-item)
    display:none; // hide on load
  }
  div.item-0{
    display:block; // show first image also if js not ready
  }
  // Show items after owl-slider is initialized
  .owl-item{
    div[class*="item-"]{
      display:block;
    }
  }
  .views-field-field-headerimage .field-content{
    // max-height:600px;
    overflow:hidden;
    position:relative;
    // @media #{$xxlarge-up}{
    //   height:600px; // we need height to vertically center the picture / image with translateY
    // }
    picture{ // TODO: what is the best practise to handle this thing?
      display:block;
      // @media #{$xxlarge-up}{
      //   position:relative;
      //   top:50%;
      //   transform: translateY(-50%);
      // }
    }
    img{
      max-width:2560px;
      @media #{$xlarge-up}{
        width:100%;
      }
    }
  }
  .view-empty{
    padding:0;
    border:0 none;
    border-radius:0;
  }
}

/* -- Undo nonsense done by the calendar widget module */
.view-event-calendar{
  border:0 none;
  border-radius:0;
}

/* -- -- Header Image BLOCK "Matching Images" (node edit) */
.header-image--matching-images{
  @include box-style;
}

/* =============================================================================
 *  Field Styling (Output)
 * ========================================================================== */

.field {}
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

.field-label-inline{
  .field-label,
  .field-items,
  .field-item{
    display:inline;
  }
}

// Multicolumn fields
.field-items.row{
  .field-item{
    margin-bottom: 1rem;
  }
}

/* Basic Field Types */
/* -- Body */
.field-name-body{
  @include clearfix-after;
}
.field-name-field-titelbild{
  + .field-name-body{
    &::after{
      // remove clearfix if title image is floated into it
      display:none;
    }
  }
  &.float-left,
  &.float-right{
    @media #{$large-up} {
      max-width:50%;
    }
  }
}
/* -- Image */
.field-type-image {
  .colorbox{
    cursor:zoom-in;
  }
  .field-item{
    line-height:0;
    text-align:center;
  }
}
a.lightbox-processed{ cursor: -webkit-zoom-in; cursor: -moz-zoom-in; }
// Fix colorbox z-index - we need very heigh z-index values because of leaflets bad practise using those heigh values
#cboxOverlay{
  z-index:100001;
}
#colorbox{
  z-index:100002;
}
/* -- -- Titleimage */
.field-name-field-titelbild{
  // margin-bottom:$grid_vertical;
  .image-caption{
    display:none; // hide caption per default - show only on specified locations
  }
}
.image-caption{
  background:$bluish_grey_dark;
  color:$white;
  text-align:left;
  width:100%;
  line-height:$line_height_medium;
  padding:$grid_vertical $half_grid;
}
.node.node-full{
  .field-name-field-titelbild{
    // margin-bottom:$grid_vertical;
    position:relative;
    .image-caption{
      display:block; // hide caption per default - show only on specified locations
    }
  }
}

/* -- -- Gallery */
.field-name-field-galerie{
  margin-top:$grid_vertical;
}
/* -- File */
.file{
  display:block;
  margin-bottom:3px;
  a{
    @include btn('ghost');
    @include text-overflow-ellipsis();
  }
  &-icon{
    margin-right:3px;
  }
}

// Adressfields
.addressfield-container-inline{
  .postal-code{
    + .locality{
      margin-left:4px;
    }
  }
}

/* Named (custom) Fields */
/* -- Downloads Anhaenge */
.field-name-field-attachments:not(.form-wrapper){
  // @include box-style;
  // margin-bottom:1.5em;
}

/* =============================================================================
 *   Search (Page)
 * ========================================================================== */

.custom-search-filter{
  ul{
    list-style:none;
    margin:0;
    padding:0;
  }
  li{
    list-style:none;
    margin:0;
    paddding:0;
    display:inline-block;
  }
  a{
    @include btn('ghost');
  }
}

/* =============================================================================
 *   Node Styling
 * ========================================================================== */

.node {
  margin-bottom: 20px;
  &.view-mode-full{
    .field{
      margin-bottom:$grid_vertical / 2;
    }
  }
  .links.inline{
    text-align:right;
    a{
      @include btn('ghost');
    }
    .node-readmore > a{
      @include btn-mod('primary');
      &::before{
        font-family:$iconfont;
        margin-right:.25em;
        @include get-ico-only('circle-arrow-right')
      }
    }
    .calendar_link > a{
      &::before{
        font-family:$iconfont;
        margin-right:.25em;
        @include get-ico-only('calendar')
      }
    }
  }
}

.node .node-title {
  margin: 0;
}

// Ansprechpartner
.node-kultur-ansprechpartner{
  &.node-teaser{
    .field-name-field-titelbild{
      float:none;
      margin:0;
      max-width:100%;
    }
    .image-caption{
      display:none !important;
    }
    .two-33-66 > .row{
      @media #{$medium-up} {
        display:flex;
        > .region{
          flex-grow:0;
          flex-shrink:0;
        }
      }
    }
    .region-two-33-66-first {

    }
    .region-two-33-66-second{
      > .region-inner{
        height:100%;
        display:flex;
        flex-direction: column;
      }
    }
    .pane-node-links{
      margin-top:auto;
      margin-bottom:0;
    }
  }
}

/* -- Author Info */
footer.submitted{
  background: none repeat scroll 0 0 #F3F3F3;
  clear: both;
  color: #6F6F6F;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top;
  .user-picture {
    border: 5px solid #F3F3F3;
    background:#fff;
    float: left;
    line-height: 0;
    margin-right:15px;
    margin-left: -15px;
    margin-top: -10px;
  }
}

/* POIs */
.node-poi{
  &.node-teaser{
    // Teaser
    .node__title{
      display:inline-block;
      margin-right:3px;
    }
    .tagging{
      display:inline-block;
      vertical-align:middle;
      .field{
        margin-bottom:0;
      }
    }
    .media-object__content{
      > .field:not(:last-child){
        margin-bottom:1em;
      }
    }
  }
  &.view-mode-full{
    // Subline
    .field-name-field-subline{
      margin-top:-20px;
      margin-bottom:25px;
    }
    // POI Detail
    .field-name-field-poi-related{
      .field-label{
        @include section-title();
      }
    }
  }
}
.poi-detail__location{
  .field{
    margin-bottom:3px;
  }
  .field-name-field-poi-position{
    // Map
    .leaflet-container{
      max-height:271px;
    }
  }
}
.poi-detail__contact{
  .field{
    margin-bottom:3px;
  }
}

// Leaflet custom tooltips via tagget marker - the default popups and tooltips are not working.
.leaflet-tagged-marker{
  div.tag-inside-marker{
    position:absolute;
    top:100%;
    left:50%;
    width:250px;
    margin-left:-125px;
    background:white;
    padding:.5rem;
    display:none;
  }
  &:hover{
    div.tag-inside-marker{
      display:block;
    }
  }
}


/* =============================================================================
 *   Tokens
 * ========================================================================== */

/*  Kontakt */
.contact-container .item-content {
    line-height: 20px;
    vertical-align: middle;
}
.contact-container .item {
    display: block;
    margin-bottom: 0.4em;
}
.contact-container br{ display:none; } /* BR is needed for HTML Mails */

/* =============================================================================
 *   Tables
 * ========================================================================== */

table.sticky-header {
  z-index: 10;
}

tr.odd {
  background: #fff;
}

tr.info,
tr.even,
tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: lighten($grey_light, 10%);
}

tr.odd td.active {
  background-color: lighten($grey_light, 5%);
}

tr.even td.active {
  background-color: lighten($grey_light, 2%);
}

/* =============================================================================
 *   Panels
 * ========================================================================== */

.panel-pane{
  margin-bottom:$grid_vertical;
  h1,
  h2,
  h3{
    &:first-of-type{
      margin-top:0;
    }
  }
  &--highlight-sec{
    .pane-title{
      border-color:$highlight_sec;
    }
  }
}
.pane-title{
  @include section-title();
}

/* Frontpage */
.frontpage-panel{
  .panel-bootstrap{
    > .row-inside-last{
      @include bold-cutline(top);
    }
  }
}
/* -- Karte Pois */
.pane-stadtmarketing-poi-s-panel-pane-map1{
  + .pane-stadtmarketing-poi-s-kategorien-panel-pane-1{
    margin-top:$grid_vertical * -1;
  }
}
/* Veranstaltungen / Events */
.page-node-36{
  // Uebersicht
  .slide-overlay--event{
    background:$highlight_sec;
    .date-rect > .inner{
      background-color:$highlight_sec_dark;
    }
  }
}

// Veranstaltungen / Event Detail
.luebbecke-event{
  &__time{
    display:flex;
    align-items: center;
    border:1px solid $bluish_grey_lighter;
    + .panel-pane{
      margin-top:1rem;
    }
    .pane-node-field-event-zeitraum{
      flex:0 1 auto;
      margin:0 !important;
      &:nth-child(2){
        text-align:center;
        flex-grow:1;
        .date-display-range{
          @include ico('clock');
        }
      }
      .date-rect{
        min-height:80px;
      }
    }
  }
  .field-name-field-event-ticket-online-url{
    a{
      @include btn('green', 'default', 'ticket');
      display:block;
    }
  }
  .field-name-field-galerie{
    .field-items{
      display:flex;
      flex-wrap:wrap;
      justify-content: flex-start;
      margin-left: ceil($thin_grid / 2) * -1;
      margin-right: ceil($thin_grid / 2) * -1;
    }
    .field-item{
      flex:0 1 33.333%;
      padding-left:ceil($thin_grid / 2);
      padding-right:ceil($thin_grid / 2);
      margin-bottom:ceil($grid_vertical / 2);
    }
  }
  .pane-node-field-poi-typ-vvk{
    .field-item{
      margin-bottom:.5rem;
    }
  }
  // Sidebar
  .region-two-66-33-second{
    .panel-pane{
      margin-bottom:1rem;
    }
    .node{
      margin-bottom:0;
    }
  }
}

/* =============================================================================
 *   Views
 * ========================================================================== */
/* Empty */
.view-empty{
  @include box-style;
  font-size: .9em;
  padding: 2em;
  text-align: center;
}

/* Header & Footer */
.view-header{ margin-bottom:1em; }
.view-footer{ }

/* Attachements */
.attachment-before{
  margin-bottom:$grid_vertical;
}
.attachment-after{
  margin-top:$grid_vertical;
}

/* Lists */
.item-list:not(.list-style){
  > ul{
    margin:0;
    padding:0;
    > .views-row{
      list-style:none;
    }
  }
}

/* Row */
.views-row{
  margin-bottom:$grid_vertical / 2;
  @include clearfix-after;
  .node{
    // prevent double margin
    margin-bottom:0;
  }
}

/* -- Alternating Views Row Background */
.alternate-backgrounds .views-row{
  padding-right:10px;
  &.views-row-even{
    background:$grey_light;
    padding-top:0.5em;
    padding-bottom:0.5em;
    h3{
      margin-top:0;
    }
  }
}

/* Poi Categories */
.view-stadtmarketing-poi-s-kategorien{
  border:1px solid $grey_light;
  padding:$thin_grid;
  .item-list{
    ul{
      display: flex;
      flex-wrap: wrap;
      margin-left:$thin_grid * -1;
      margin-right:$thin_grid * -1;
      li{
        padding-left:$thin_grid !important;
        padding-right:$thin_grid !important;
        margin-bottom:5px !important;
        flex-basis:97%;
        @media #{$enough-width}{
           flex-basis:50%;
        }
        a{
          text-align:left;
          margin-left:20px;
          padding-left:33px;
          .ico-seperate{
            // Icons
            height:40px;
            width:40px;
            float:left;
            overflow:hidden;
            line-height:40px;
            border-radius:50%;
            background-color:$grey_dark;
            color:white;
            margin-top: -12px;
            margin-left: -50px;
          }
          &:hover{
            .ico-seperate{
              transform: scale(1.15);
            }
          }
        }
      }
    }
  }
}

/* Filter */
.views-exposed-form{
  // Hide submit button on auto submit filters
  .ctools-auto-submit-full-form .views-submit-button{
    display:none;
  }
}
.views-exposed-widget{
  margin-bottom:$thin_grid;
  // Date Filter
  &.views-widget-filter-date_filter{
    > label{
      display:none;
    }
  }
  .date-padding{
    display:block;
    .form-item{
      margin:0;
    }
  }
}

/* Read On Links */
.more-link,
.read-on{
  text-align:right;
  display:block;
  clear:both;
  a{
    @include btn('default', 'default', 'circle-arrow-right');
  }
}
td.read-on a{
  display:block;
  text-align:left;
  margin-bottom:0.25em;
}

/* Image */
.view-image{ margin: 0.5em 0; text-align:center; }

/* Glossar */
.view-id-glossary{
  .views-row{
    clear:both;
    margin-left: $grid / 2;
  }
  .attachment{
    margin-bottom:1.5em;
    color: $grey;
  }
  .views-summary{
    position:relative;
    a{
      text-decoration:none;
      padding:3px 5px;
      display:inline-block;
      min-width:15px;
      text-align:center;
    }
    .count{
      display:none;
    }
    &:hover{
      .count{
        position:absolute;
        bottom:17px;
        right:-2px;
        padding:2px;
        border-radius:$border_radius;
        font-size:0.65em;
        line-height:normal;
        display:inline;
      }
    }
    &:hover a,
    a.active{
      border-radius:$border_radius_small;
      margin-top:-3px;
      background:$highlight;
      color:#fff;
    }
    .view-content{
      a{
        display:inline-block;
        &:first-letter{
          font-size:1.2em;
          color:$highlight;
        }
      }
    }
  }
}

#admin-menu{
  z-index:100000000;
}

@import "modules/eu_cookie_compliance";
@import "modules/mytube_embed_video_cookie_compliance";
