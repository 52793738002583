@charset "UTF-8";
/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/*
 * @file
 * global.styles
 */
html {
  background-color: #f4f4f4; }

body {
  color: #484848;
  background: transparent; }

/* =============================================================================
 *   Wrappers
 * ========================================================================== */
.header-top-bar {
  background: white;
  height: 65px; }
  .header-top-bar > .inner {
    margin: 0 auto;
    max-width: 1200px; }

.page {
  background: white;
  margin: 0 auto; }

.page-header {
  background-color: white;
  min-height: 190px; }
  @media (max-width: 991px) {
    .page-header {
      display: none; } }
  .page-header > .inner {
    clear: both; }

.block-panels-mini-header-map .panel-pane {
  margin-bottom: 0; }

.page-footer {
  border-top: 8px solid #ebebeb;
  padding-top: 25px;
  margin-top: 25px;
  color: #959ba4; }
  .page-footer a {
    color: #959ba4; }
    .page-footer a:hover {
      color: #484848; }
  .page-footer .block-title {
    color: #484848;
    font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
    font-weight: 600; }
    .page-footer .block-title a {
      color: #484848; }
  .page-footer .block:not(:last-child) {
    box-shadow: 0 1px 0 #ebebeb;
    padding-bottom: 15px;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .page-footer .block:not(:last-child) {
        box-shadow: 1px 0 0 #ebebeb;
        padding-right: 22px;
        margin-right: 0; } }

/* =============================================================================
 *   Branding
 * ========================================================================== */
/*
 * Wraps all the branding elements, logo, name and slogan
 */
@media only screen and (min-width: 768px) {
  .branding {
    float: left;
    width: 35%; } }

/*
 * Logo
 */
.page-logo {
  padding: 0;
  margin-top: -8px;
  text-align: center; }
  @media only screen and (min-width: 992px) {
    .page-logo {
      text-align: left;
      margin-bottom: -50px;
      position: relative;
      z-index: 5;
      padding: 8px 15px 5px;
      background-color: white;
      box-shadow: 0 9px 3px -5px rgba(0, 0, 0, 0.4); } }
  .page-logo a {
    display: block; }
  .page-logo img {
    vertical-align: bottom; }

/*
 * Site name (h1)
 */
.page-name {
  margin: 0; }

/*
 * Site slogan (h2)
 */
.page-slogan {
  margin: 0; }

/* =============================================================================
 *   Animations (CSS)
 * ========================================================================== */
*,
*::before,
*::after {
  transition-property: color, background-color, border-color, transform;
  transition-duration: 300ms; }

@keyframes pulsating-glow {
  0% {
    box-shadow: 0 0 3px #d02b2b; }
  50% {
    box-shadow: 0 0 10px #d02b2b; }
  100% {
    box-shadow: 0 0 3px #d02b2b; } }

/* Dragging complete (eg. Sticky Tables) */
@keyframes dragging-complete {
  0% {
    background: #f5ffea; }
  100% {
    background: #fff; } }

/* Swipe In (eg. mobile menu trail) */
@keyframes swipe-in {
  0% {
    transform: translate(-150px, 0); }
  100% {
    transform: translate(0, 0); } }

/* Parallax Scrolling */
.parallax-container {
  overflow: hidden;
  height: 600px;
  position: relative; }

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }
  .parallax .parallax-image-container {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }

/* =============================================================================
 *   Regions
 * ========================================================================== */
.region-header-top-bar {
  padding-top: 8px;
  padding-bottom: 8px; }
  .region-header-top-bar > .region-inner {
    display: flex;
    justify-content: flex-end; }
  @media only screen and (min-width: 768px) {
    .region-header-top-bar {
      float: left;
      width: 65%; } }
  .region-header-top-bar .block {
    margin-left: 15px; }
  .region-header-top-bar .block-search-form {
    max-width: 445px;
    width: 100%;
    float: right; }

.region-header .menu {
  width: auto; }
  .region-header .menu::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .region-header .menu > li {
    display: block;
    float: left; }
  .region-header .menu li {
    float: none;
    display: inline-block;
    margin-right: 15px; }
    .region-header .menu li:last-child {
      margin-right: 0; }
    .region-header .menu li a {
      padding: 2px 0; }

.region-header-slide {
  clear: both;
  text-align: center; }

.navigation-bar-wrapper {
  clear: both;
  margin-bottom: 10px;
  margin-left: -30px;
  margin-right: -30px; }
  .navigation-bar-wrapper::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  @media only screen and (min-width: 992px) {
    .navigation-bar-wrapper > .inner {
      float: left;
      position: relative;
      z-index: 99999;
      height: 54px;
      margin-top: -54px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.65) 0%, white 100%);
      padding: 4px 4px 0 4px;
      border-radius: 8px 8px 0 0; } }

.region-help .block {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }
  .region-help .block::before {
    font-family: "iconfont";
    position: absolute;
    left: 15px;
    top: 14px;
    font-size: 25px;
    line-height: normal; }
  .region-help .block ul {
    margin-top: 0;
    margin-bottom: 0; }
  .region-help .block a {
    color: #31708f; }
  .region-help .block::before {
    content: ""; }

.sidebar-wrapper .block {
  margin-bottom: 25px; }

.sidebar-wrapper .block-title {
  border-bottom: 1px solid #959ba4;
  padding-bottom: 4px;
  margin-bottom: 12.5px; }

.sidebar-wrapper .menu li a {
  padding-left: 0;
  padding-right: 0; }
  .sidebar-wrapper .menu li a.active {
    color: #0e4dac;
    font-weight: 600; }

.sidebar-wrapper .menu li.depth-1.expandable-menu-item.open > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 45px;
  font-weight: 600; }

.sidebar-wrapper .menu li.depth-1 > .btn-expandable-menu-item--closer {
  width: 40px;
  height: 41px;
  background: #ebebeb;
  padding: 3px;
  border-radius: 5px 5px 0 0;
  top: 0; }
  .sidebar-wrapper .menu li.depth-1 > .btn-expandable-menu-item--closer::before {
    left: 7px;
    top: 7px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #0e4dac 0%, #074093 100%); }

.sidebar-wrapper .menu .menu {
  background: #ebebeb;
  padding: 15px;
  border-radius: 5px 0 5px 5px; }

.view-event-calendar {
  border: 1px solid #ebebeb;
  border-radius: 0 0 5px 5px; }
  .view-event-calendar .view-header {
    background: #0e4dac;
    color: #fff;
    margin: 0;
    padding: 8px 0 0 0; }
  .view-event-calendar .date-nav-wrapper .date-nav {
    padding-left: 37px;
    padding-right: 37px;
    position: relative;
    margin-bottom: 0; }
  .view-event-calendar .date-nav-wrapper .date-prev,
  .view-event-calendar .date-nav-wrapper .date-next {
    position: absolute;
    top: 4px; }
    .view-event-calendar .date-nav-wrapper .date-prev > a,
    .view-event-calendar .date-nav-wrapper .date-next > a {
      height: 30px;
      width: 30px;
      text-indent: -9000px;
      line-height: 13px;
      border-radius: 50%;
      background: #074093;
      font-size: 12px; }
      .view-event-calendar .date-nav-wrapper .date-prev > a::before,
      .view-event-calendar .date-nav-wrapper .date-next > a::before {
        font-family: "iconfont";
        text-indent: 0;
        float: right;
        vertical-align: middle;
        text-align: center;
        width: 100%; }
  .view-event-calendar .date-nav-wrapper .date-prev {
    left: 5px; }
    .view-event-calendar .date-nav-wrapper .date-prev > a::before {
      content: ""; }
  .view-event-calendar .date-nav-wrapper .date-next {
    right: 5px; }
    .view-event-calendar .date-nav-wrapper .date-next > a::before {
      content: ""; }
  .view-event-calendar .date-heading {
    text-align: center; }
    .view-event-calendar .date-heading h3 {
      margin: 0;
      padding: 7px 0;
      font-size: 16px;
      font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
      color: #fff; }
      .view-event-calendar .date-heading h3 a {
        color: #fff; }
    .view-event-calendar .date-heading .pager > li {
      background: none;
      padding: 0;
      margin: 0; }
  .view-event-calendar .view-content table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    line-height: 1em;
    text-align: center; }
  .view-event-calendar .view-content thead {
    background: #0e4dac;
    color: #fff; }
    .view-event-calendar .view-content thead th {
      background: none;
      border: 0 none;
      text-align: center;
      padding: 8px 1px 8px 1px;
      white-space: nowrap;
      font-weight: 400; }
      .view-event-calendar .view-content thead th:first-child {
        padding-left: 8px; }
      .view-event-calendar .view-content thead th:last-child {
        padding-right: 8px; }
  .view-event-calendar .view-content tbody td:first-child {
    padding-left: 8px; }
  .view-event-calendar .view-content tbody td:last-child {
    padding-right: 8px; }
  .view-event-calendar .view-content .date-box {
    text-align: right; }
  .view-event-calendar .view-content .multi-day .inner {
    margin-left: 10px;
    margin-right: 10px;
    position: relative; }
  .view-event-calendar .view-content .multi-day .continuation {
    position: absolute;
    left: -10px;
    top: 2px; }
  .view-event-calendar .view-content .multi-day .continues {
    position: absolute;
    right: -10px;
    top: 2px; }
  .view-event-calendar .view-content .contents a {
    background-color: #78aaf4;
    color: #fff;
    display: block;
    text-align: center;
    padding: 3px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    text-decoration: none;
    border-radius: 5px;
    margin-bottom: 1px;
    transition-property: background-color;
    transition-duration: 250ms; }
  .view-event-calendar .view-content .contents:hover a {
    background: #d02b2b; }
  .view-event-calendar .view-content table.mini {
    table-layout: initial;
    width: 100%; }
    .view-event-calendar .view-content table.mini .calendar-empty {
      display: none; }
    .view-event-calendar .view-content table.mini tbody tr:first-child {
      border-top: 5px solid white; }
    .view-event-calendar .view-content table.mini tbody tr:last-child {
      border-bottom: 5px solid white; }
    .view-event-calendar .view-content table.mini td {
      line-height: 1em; }
      .view-event-calendar .view-content table.mini td a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        color: #959ba4;
        text-decoration: none; }
      .view-event-calendar .view-content table.mini td.empty a {
        color: #ebebeb; }
      .view-event-calendar .view-content table.mini td.has-no-events a {
        color: #959ba4; }
      .view-event-calendar .view-content table.mini td.has-events a {
        color: #0e4dac; }
        .view-event-calendar .view-content table.mini td.has-events a:hover {
          background-color: #0e4dac;
          color: #fff; }
      .view-event-calendar .view-content table.mini td.today a {
        background-color: #78aaf4;
        color: #fff; }

.first-sidebar-wrapper {
  border-right: 8px solid #ebebeb; }

.second-sidebar-wrapper {
  border-left: 8px solid #ebebeb; }

.region-footer .menu li a {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0; }

/* =============================================================================
 *   Formatter Classes
 * ========================================================================== */
.text-center {
  text-align: center; }
  .text-center .block-title {
    text-align: left; }

/* =============================================================================
 *   Block Styling
 * ========================================================================== */
.block-title {
  margin-top: 0; }

.block-content p:last-child {
  margin-bottom: 0; }

/* Block Specific */
/* -- Search */
.region-header .block-search {
  margin-bottom: 0;
  margin-top: 15px;
  display: none; }
  @media only screen and (min-width: 768px) {
    .region-header .block-search {
      float: right;
      display: block; } }

/* -- Header Image */
.block-views-header-slideshow-block {
  line-height: 0;
  display: none; }
  @media only screen and (min-width: 768px) {
    .block-views-header-slideshow-block {
      display: block; } }

.view-header-slideshow {
  position: relative; }
  .view-header-slideshow div[class*="item-"] {
    display: none; }
  .view-header-slideshow div.item-0 {
    display: block; }
  .view-header-slideshow .owl-item div[class*="item-"] {
    display: block; }
  .view-header-slideshow .views-field-field-headerimage .field-content {
    overflow: hidden;
    position: relative; }
    .view-header-slideshow .views-field-field-headerimage .field-content picture {
      display: block; }
    .view-header-slideshow .views-field-field-headerimage .field-content img {
      max-width: 2560px; }
      @media only screen and (min-width: 1140px) {
        .view-header-slideshow .views-field-field-headerimage .field-content img {
          width: 100%; } }
  .view-header-slideshow .view-empty {
    padding: 0;
    border: 0 none;
    border-radius: 0; }

/* -- Undo nonsense done by the calendar widget module */
.view-event-calendar {
  border: 0 none;
  border-radius: 0; }

/* -- -- Header Image BLOCK "Matching Images" (node edit) */
.header-image--matching-images {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }

/* =============================================================================
 *  Field Styling (Output)
 * ========================================================================== */
.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0; }

.field-label-inline .field-label,
.field-label-inline .field-items,
.field-label-inline .field-item {
  display: inline; }

.field-items.row .field-item {
  margin-bottom: 1rem; }

/* Basic Field Types */
/* -- Body */
.field-name-body::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden; }

.field-name-field-titelbild + .field-name-body::after {
  display: none; }

@media only screen and (min-width: 992px) {
  .field-name-field-titelbild.float-left, .field-name-field-titelbild.float-right {
    max-width: 50%; } }

/* -- Image */
.field-type-image .colorbox {
  cursor: zoom-in; }

.field-type-image .field-item {
  line-height: 0;
  text-align: center; }

a.lightbox-processed {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in; }

#cboxOverlay {
  z-index: 100001; }

#colorbox {
  z-index: 100002; }

/* -- -- Titleimage */
.field-name-field-titelbild .image-caption {
  display: none; }

.image-caption {
  background: #959ba4;
  color: #fff;
  text-align: left;
  width: 100%;
  line-height: 1.35em;
  padding: 25px 22px; }

.node.node-full .field-name-field-titelbild {
  position: relative; }
  .node.node-full .field-name-field-titelbild .image-caption {
    display: block; }

/* -- -- Gallery */
.field-name-field-galerie {
  margin-top: 25px; }

/* -- File */
.file {
  display: block;
  margin-bottom: 3px; }
  .file a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: transparent !important;
    border-color: #d0d0d0 !important;
    color: #484848 !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block; }
    .file a:hover {
      background-color: transparent !important;
      border-color: #e4e4e4 !important;
      color: #0e4dac !important; }
    .file a:active, .file a:focus {
      background-color: transparent !important;
      border-color: #c3c3c3 !important;
      color: #484848 !important; }
    .file a:hover {
      text-decoration: none; }
  .file-icon {
    margin-right: 3px; }

.addressfield-container-inline .postal-code + .locality {
  margin-left: 4px; }

/* Named (custom) Fields */
/* -- Downloads Anhaenge */
/* =============================================================================
 *   Search (Page)
 * ========================================================================== */
.custom-search-filter ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.custom-search-filter li {
  list-style: none;
  margin: 0;
  paddding: 0;
  display: inline-block; }

.custom-search-filter a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  .custom-search-filter a:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .custom-search-filter a:active, .custom-search-filter a:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }
  .custom-search-filter a:hover {
    text-decoration: none; }

/* =============================================================================
 *   Node Styling
 * ========================================================================== */
.node {
  margin-bottom: 20px; }
  .node.view-mode-full .field {
    margin-bottom: 12.5px; }
  .node .links.inline {
    text-align: right; }
    .node .links.inline a {
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.05em;
      cursor: pointer;
      text-align: left;
      text-decoration: none;
      vertical-align: top;
      user-select: none;
      background-color: transparent !important;
      border-color: #d0d0d0 !important;
      color: #484848 !important;
      padding: 12px 12px;
      border-radius: 5px;
      line-height: 18px;
      font-size: 16px; }
      .node .links.inline a:hover {
        background-color: transparent !important;
        border-color: #e4e4e4 !important;
        color: #0e4dac !important; }
      .node .links.inline a:active, .node .links.inline a:focus {
        background-color: transparent !important;
        border-color: #c3c3c3 !important;
        color: #484848 !important; }
      .node .links.inline a:hover {
        text-decoration: none; }
    .node .links.inline .node-readmore > a {
      border-color: #0e4dac !important;
      background-color: #0e4dac !important;
      color: #fff !important; }
      .node .links.inline .node-readmore > a:hover {
        border-color: #1058c4 !important;
        background-color: #1058c4 !important;
        color: #fff !important; }
      .node .links.inline .node-readmore > a:active, .node .links.inline .node-readmore > a:focus {
        border-color: #0c4294 !important;
        background-color: #0c4294 !important;
        color: #fff !important; }
      .node .links.inline .node-readmore > a::before {
        font-family: "iconfont";
        margin-right: .25em;
        content: ""; }
    .node .links.inline .calendar_link > a::before {
      font-family: "iconfont";
      margin-right: .25em;
      content: ""; }

.node .node-title {
  margin: 0; }

.node-kultur-ansprechpartner.node-teaser .field-name-field-titelbild {
  float: none;
  margin: 0;
  max-width: 100%; }

.node-kultur-ansprechpartner.node-teaser .image-caption {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .node-kultur-ansprechpartner.node-teaser .two-33-66 > .row {
    display: flex; }
    .node-kultur-ansprechpartner.node-teaser .two-33-66 > .row > .region {
      flex-grow: 0;
      flex-shrink: 0; } }

.node-kultur-ansprechpartner.node-teaser .region-two-33-66-second > .region-inner {
  height: 100%;
  display: flex;
  flex-direction: column; }

.node-kultur-ansprechpartner.node-teaser .pane-node-links {
  margin-top: auto;
  margin-bottom: 0; }

/* -- Author Info */
footer.submitted {
  background: none repeat scroll 0 0 #F3F3F3;
  clear: both;
  color: #6F6F6F;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top; }
  footer.submitted .user-picture {
    border: 5px solid #F3F3F3;
    background: #fff;
    float: left;
    line-height: 0;
    margin-right: 15px;
    margin-left: -15px;
    margin-top: -10px; }

/* POIs */
.node-poi.node-teaser .node__title {
  display: inline-block;
  margin-right: 3px; }

.node-poi.node-teaser .tagging {
  display: inline-block;
  vertical-align: middle; }
  .node-poi.node-teaser .tagging .field {
    margin-bottom: 0; }

.node-poi.node-teaser .media-object__content > .field:not(:last-child) {
  margin-bottom: 1em; }

.node-poi.view-mode-full .field-name-field-subline {
  margin-top: -20px;
  margin-bottom: 25px; }

.node-poi.view-mode-full .field-name-field-poi-related .field-label {
  padding-bottom: 7.5px;
  border-bottom: 2px solid #a2a8b0;
  margin-bottom: 15px;
  font-size: 26px;
  line-height: 1.1em;
  font-weight: 400;
  font-family: "Crimson Text", Georgia, serif; }

.poi-detail__location .field {
  margin-bottom: 3px; }

.poi-detail__location .field-name-field-poi-position .leaflet-container {
  max-height: 271px; }

.poi-detail__contact .field {
  margin-bottom: 3px; }

.leaflet-tagged-marker div.tag-inside-marker {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 250px;
  margin-left: -125px;
  background: white;
  padding: .5rem;
  display: none; }

.leaflet-tagged-marker:hover div.tag-inside-marker {
  display: block; }

/* =============================================================================
 *   Tokens
 * ========================================================================== */
/*  Kontakt */
.contact-container .item-content {
  line-height: 20px;
  vertical-align: middle; }

.contact-container .item {
  display: block;
  margin-bottom: 0.4em; }

.contact-container br {
  display: none; }

/* BR is needed for HTML Mails */
/* =============================================================================
 *   Tables
 * ========================================================================== */
table.sticky-header {
  z-index: 10; }

tr.odd {
  background: #fff; }

tr.info,
tr.even,
tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: white; }

tr.odd td.active {
  background-color: #f8f8f8; }

tr.even td.active {
  background-color: #f0f0f0; }

/* =============================================================================
 *   Panels
 * ========================================================================== */
.panel-pane {
  margin-bottom: 25px; }
  .panel-pane h1:first-of-type,
  .panel-pane h2:first-of-type,
  .panel-pane h3:first-of-type {
    margin-top: 0; }
  .panel-pane--highlight-sec .pane-title {
    border-color: #d02b2b; }

.pane-title {
  padding-bottom: 7.5px;
  border-bottom: 2px solid #a2a8b0;
  margin-bottom: 15px;
  font-size: 26px;
  line-height: 1.1em;
  font-weight: 400;
  font-family: "Crimson Text", Georgia, serif; }

/* Frontpage */
.frontpage-panel .panel-bootstrap > .row-inside-last {
  border-top: 8px solid #ebebeb;
  padding-top: 25px;
  margin-top: 25px; }

/* -- Karte Pois */
.pane-stadtmarketing-poi-s-panel-pane-map1 + .pane-stadtmarketing-poi-s-kategorien-panel-pane-1 {
  margin-top: -25px; }

/* Veranstaltungen / Events */
.page-node-36 .slide-overlay--event {
  background: #d02b2b; }
  .page-node-36 .slide-overlay--event .date-rect > .inner {
    background-color: #b12727; }

.luebbecke-event__time {
  display: flex;
  align-items: center;
  border: 1px solid #e4e7eb; }
  .luebbecke-event__time + .panel-pane {
    margin-top: 1rem; }
  .luebbecke-event__time .pane-node-field-event-zeitraum {
    flex: 0 1 auto;
    margin: 0 !important; }
    .luebbecke-event__time .pane-node-field-event-zeitraum:nth-child(2) {
      text-align: center;
      flex-grow: 1; }
      .luebbecke-event__time .pane-node-field-event-zeitraum:nth-child(2) .date-display-range::before {
        font-family: "iconfont";
        font-weight: normal !important;
        font-style: normal !important;
        margin-right: 4px;
        content: ""; }
    .luebbecke-event__time .pane-node-field-event-zeitraum .date-rect {
      min-height: 80px; }

.luebbecke-event .field-name-field-event-ticket-online-url a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #bcc826 !important;
  border-color: #bcc826 !important;
  color: white !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  display: block; }
  .luebbecke-event .field-name-field-event-ticket-online-url a:hover {
    background-color: #c8d528 !important;
    border-color: #c8d528 !important;
    color: white !important; }
  .luebbecke-event .field-name-field-event-ticket-online-url a:active, .luebbecke-event .field-name-field-event-ticket-online-url a:focus {
    background-color: #b0bb24 !important;
    border-color: #b0bb24 !important;
    color: #6b7216 !important; }
  .luebbecke-event .field-name-field-event-ticket-online-url a::before {
    display: inline-block;
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    text-align: center;
    content: ""; }
    .luebbecke-event .field-name-field-event-ticket-online-url a::before else {
      line-height: 1; }
  .luebbecke-event .field-name-field-event-ticket-online-url a:hover {
    text-decoration: none; }

.luebbecke-event .field-name-field-galerie .field-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -8px;
  margin-right: -8px; }

.luebbecke-event .field-name-field-galerie .field-item {
  flex: 0 1 33.333%;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 13px; }

.luebbecke-event .pane-node-field-poi-typ-vvk .field-item {
  margin-bottom: .5rem; }

.luebbecke-event .region-two-66-33-second .panel-pane {
  margin-bottom: 1rem; }

.luebbecke-event .region-two-66-33-second .node {
  margin-bottom: 0; }

/* =============================================================================
 *   Views
 * ========================================================================== */
/* Empty */
.view-empty {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1;
  font-size: .9em;
  padding: 2em;
  text-align: center; }

/* Header & Footer */
.view-header {
  margin-bottom: 1em; }

/* Attachements */
.attachment-before {
  margin-bottom: 25px; }

.attachment-after {
  margin-top: 25px; }

/* Lists */
.item-list:not(.list-style) > ul {
  margin: 0;
  padding: 0; }
  .item-list:not(.list-style) > ul > .views-row {
    list-style: none; }

/* Row */
.views-row {
  margin-bottom: 12.5px; }
  .views-row::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .views-row .node {
    margin-bottom: 0; }

/* -- Alternating Views Row Background */
.alternate-backgrounds .views-row {
  padding-right: 10px; }
  .alternate-backgrounds .views-row.views-row-even {
    background: #ebebeb;
    padding-top: 0.5em;
    padding-bottom: 0.5em; }
    .alternate-backgrounds .views-row.views-row-even h3 {
      margin-top: 0; }

/* Poi Categories */
.view-stadtmarketing-poi-s-kategorien {
  border: 1px solid #ebebeb;
  padding: 15px; }
  .view-stadtmarketing-poi-s-kategorien .item-list ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .view-stadtmarketing-poi-s-kategorien .item-list ul li {
      padding-left: 15px !important;
      padding-right: 15px !important;
      margin-bottom: 5px !important;
      flex-basis: 97%; }
      @media only screen and (min-width: 768px) {
        .view-stadtmarketing-poi-s-kategorien .item-list ul li {
          flex-basis: 50%; } }
      .view-stadtmarketing-poi-s-kategorien .item-list ul li a {
        text-align: left;
        margin-left: 20px;
        padding-left: 33px; }
        .view-stadtmarketing-poi-s-kategorien .item-list ul li a .ico-seperate {
          height: 40px;
          width: 40px;
          float: left;
          overflow: hidden;
          line-height: 40px;
          border-radius: 50%;
          background-color: #3f3f3f;
          color: white;
          margin-top: -12px;
          margin-left: -50px; }
        .view-stadtmarketing-poi-s-kategorien .item-list ul li a:hover .ico-seperate {
          transform: scale(1.15); }

/* Filter */
.views-exposed-form .ctools-auto-submit-full-form .views-submit-button {
  display: none; }

.views-exposed-widget {
  margin-bottom: 15px; }
  .views-exposed-widget.views-widget-filter-date_filter > label {
    display: none; }
  .views-exposed-widget .date-padding {
    display: block; }
    .views-exposed-widget .date-padding .form-item {
      margin: 0; }

/* Read On Links */
.more-link,
.read-on {
  text-align: right;
  display: block;
  clear: both; }
  .more-link a,
  .read-on a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #0e4dac !important;
    background-color: #0e4dac !important;
    color: #fff !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px; }
    .more-link a:hover,
    .read-on a:hover {
      border-color: #1058c4 !important;
      background-color: #1058c4 !important;
      color: #fff !important; }
    .more-link a:active, .more-link a:focus,
    .read-on a:active,
    .read-on a:focus {
      border-color: #0c4294 !important;
      background-color: #0c4294 !important;
      color: #fff !important; }
    .more-link a::before,
    .read-on a::before {
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center;
      content: ""; }
      .more-link a::before else,
      .read-on a::before else {
        line-height: 1; }
    .more-link a:hover,
    .read-on a:hover {
      text-decoration: none; }

td.read-on a {
  display: block;
  text-align: left;
  margin-bottom: 0.25em; }

/* Image */
.view-image {
  margin: 0.5em 0;
  text-align: center; }

/* Glossar */
.view-id-glossary .views-row {
  clear: both;
  margin-left: 21.5px; }

.view-id-glossary .attachment {
  margin-bottom: 1.5em;
  color: #959ba4; }

.view-id-glossary .views-summary {
  position: relative; }
  .view-id-glossary .views-summary a {
    text-decoration: none;
    padding: 3px 5px;
    display: inline-block;
    min-width: 15px;
    text-align: center; }
  .view-id-glossary .views-summary .count {
    display: none; }
  .view-id-glossary .views-summary:hover .count {
    position: absolute;
    bottom: 17px;
    right: -2px;
    padding: 2px;
    border-radius: 8px;
    font-size: 0.65em;
    line-height: normal;
    display: inline; }
  .view-id-glossary .views-summary:hover a,
  .view-id-glossary .views-summary a.active {
    border-radius: 5px;
    margin-top: -3px;
    background: #0e4dac;
    color: #fff; }
  .view-id-glossary .views-summary .view-content a {
    display: inline-block; }
    .view-id-glossary .views-summary .view-content a:first-letter {
      font-size: 1.2em;
      color: #0e4dac; }

#admin-menu {
  z-index: 100000000; }

#sliding-popup {
  position: fixed;
  z-index: 9999999999;
  left: 0;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.85) !important;
  color: #fff;
  padding: 15px 10px;
  font-size: 14px;
  transition-duration: 100ms;
  transition-property: transform;
  box-sizing: border-box; }
  #sliding-popup * {
    box-sizing: border-box; }
  #sliding-popup .popup-content {
    background: none;
    color: #fff;
    border: 0 none;
    max-width: 1140px;
    margin: 0 auto;
    background: none; }
    #sliding-popup .popup-content__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      margin-left: -1rem;
      margin-right: -1rem; }
      @media screen and (min-width: 992px) {
        #sliding-popup .popup-content__inner {
          flex-flow: nowrap; } }
      #sliding-popup .popup-content__inner > * {
        padding-left: 1rem;
        padding-right: 1rem; }
    @media screen and (min-width: 768px) {
      #sliding-popup .popup-content {
        flex-wrap: nowrap; } }
    #sliding-popup .popup-content::before {
      display: none; }
    #sliding-popup .popup-content #popup-text {
      margin-bottom: 1rem;
      min-width: 300px;
      max-width: 100vw; }
      @media screen and (min-width: 768px) {
        #sliding-popup .popup-content #popup-text {
          padding-right: 25px;
          margin-bottom: 0; } }
      #sliding-popup .popup-content #popup-text h2,
      #sliding-popup .popup-content #popup-text h3 {
        font-size: 1em;
        margin: 0 0 0.5em 0; }
      #sliding-popup .popup-content #popup-text a {
        color: #fff; }
      #sliding-popup .popup-content #popup-text p {
        font-size: 1em;
        font-weight: 400;
        color: #fff !important;
        display: inline;
        margin: 0; }
      #sliding-popup .popup-content #popup-text .find-more-button {
        background: none !important;
        border: 0 none;
        padding: 0;
        text-decoration: underline;
        font-size: 1em !important;
        cursor: pointer;
        color: currentColor !important;
        vertical-align: baseline; }
        #sliding-popup .popup-content #popup-text .find-more-button:hover {
          color: currentColor;
          text-decoration: underline; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories {
      min-width: 280px; }
    #sliding-popup .popup-content .eu-cookie-compliance-category {
      margin-top: 0.65em;
      margin-bottom: 0.65em; }
      #sliding-popup .popup-content .eu-cookie-compliance-category__option {
        display: flex;
        align-items: start; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"] {
          flex: 0 0 auto;
          margin: 0 0.15rem 0 0 !important; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"][disabled] {
            opacity: 0.75; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) {
            filter: #409800; }
            #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) + label {
              color: #409800; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option label {
          flex: 1 1 auto;
          padding: 0 !important;
          margin: 0 !important;
          line-height: 1.1; }
      #sliding-popup .popup-content .eu-cookie-compliance-category:not(:last-child) {
        margin-bottom: 0.35em; }
    #sliding-popup .popup-content .eu-cookie-compliance-category-description {
      font-size: 12px;
      line-height: 1.15;
      opacity: 0.65; }
    #sliding-popup .popup-content #popup-buttons {
      display: flex; }
      @media screen and (max-width: 543px) {
        #sliding-popup .popup-content #popup-buttons {
          flex-direction: column;
          flex: 1; } }
      #sliding-popup .popup-content #popup-buttons button {
        margin-bottom: 2px !important;
        white-space: nowrap; }
        #sliding-popup .popup-content #popup-buttons button.agree-button {
          background-color: #0e4dac !important;
          border-color: #0e4dac !important;
          color: white !important; }
          #sliding-popup .popup-content #popup-buttons button.agree-button:hover {
            background-color: #1058c4 !important;
            color: white !important; }
          #sliding-popup .popup-content #popup-buttons button.agree-button:active, #sliding-popup .popup-content #popup-buttons button.agree-button:focus {
            background-color: #0c4294 !important;
            color: white !important; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons {
      display: block; }
      #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button {
        display: block;
        width: 100% !important;
        margin-top: 0.2em !important;
        margin-bottom: 0.2em !important;
        text-align: center !important;
        font-size: 14px !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button:not(.agree-button) {
          padding-top: 0.35em !important;
          padding-bottom: 0.35em !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button.agree-button {
          font-weight: 600 !important; }
  #sliding-popup .eu-cookie-withdraw-tab {
    position: absolute;
    z-index: 9999;
    font-size: 1em;
    padding: 0.75em;
    text-align: center;
    text-align: center;
    display: block;
    width: 100%;
    left: 0;
    top: 0;
    white-space: nowrap;
    transform: translate(0, -100%);
    background-color: #0e4dac;
    color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer; }
    #sliding-popup .eu-cookie-withdraw-tab__edit-label {
      display: none;
      margin-left: 0.25em; }
      #sliding-popup .eu-cookie-withdraw-tab__edit-label::before {
        content: "\0028"; }
      #sliding-popup .eu-cookie-withdraw-tab__edit-label::after {
        content: "\0029"; }
    @media screen and (min-width: 544px) {
      #sliding-popup .eu-cookie-withdraw-tab {
        left: 43px;
        width: auto;
        display: inline; } }
  #sliding-popup.eu-cookie-withdraw-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
    #sliding-popup.eu-cookie-withdraw-wrapper .eu-cookie-withdraw-banner {
      background: none;
      padding-top: 15px;
      padding-bottom: 15px; }
    #sliding-popup.eu-cookie-withdraw-wrapper .popup-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap; }
      #sliding-popup.eu-cookie-withdraw-wrapper .popup-content > * {
        padding-left: 1rem;
        padding-right: 1rem; }
  #sliding-popup .eu-cookie-compliance-hidden {
    display: none !important; }

.block-euccx-euccx-cookie-settings {
  position: relative; }
  .block-euccx-euccx-cookie-settings #cookie-tabs {
    display: flex;
    flex-direction: column;
    padding: 0; }
    @media screen and (min-width: 768px) {
      .block-euccx-euccx-cookie-settings #cookie-tabs {
        flex-direction: row; } }
    .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav {
      width: auto;
      flex: 0 0 30%;
      margin: 0;
      padding-bottom: 3px;
      background: WhiteSmoke; }
      @media screen and (min-width: 768px) {
        .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav {
          padding-bottom: 0; } }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav > li > a {
        width: 100%;
        border: 0 none;
        background-color: WhiteSmoke;
        color: #484848;
        margin-bottom: 0 !important; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav > li.ui-state-active > a {
        background-color: #fff;
        font-weight: bold; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav::after {
        display: none; }
    .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel {
      position: relative;
      width: auto;
      flex: 0 0 70%;
      padding: 40px 2rem 2rem 2rem; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel h2,
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel h3 {
        font-weight: bold;
        margin-bottom: 0.5em; }

.eucc-outer {
  overflow: hidden; }

body[class*="eu-cookie-compliance"].eu-cookie-compliance-popup-open {
  padding-bottom: var(--eucc-banner-height, 100vh) !important; }
  @media screen and (min-width: 992px) {
    body[class*="eu-cookie-compliance"].eu-cookie-compliance-popup-open {
      padding-bottom: var(--eucc-banner-height, 300px) !important; } }

body[class*="eu-cookie-compliance"]:not(.eu-cookie-compliance-popup-open) .eucc-outer {
  height: var(--eucc-withdraw-tab-height, 50px);
  position: relative; }
  body[class*="eu-cookie-compliance"]:not(.eu-cookie-compliance-popup-open) .eucc-outer #sliding-popup {
    position: static;
    background: transparent !important; }
    body[class*="eu-cookie-compliance"]:not(.eu-cookie-compliance-popup-open) .eucc-outer #sliding-popup .eu-cookie-withdraw-tab {
      transform: none; }
    body[class*="eu-cookie-compliance"]:not(.eu-cookie-compliance-popup-open) .eucc-outer #sliding-popup .popup-content {
      opacity: 0; }

/* ================================================================== */
/* EUCC Button QUICKFIX */
/* === Fix new law requirements: Equaly sized and colored buttons === */
body #sliding-popup #popup-buttons .button,
body #sliding-popup #popup-buttons .button.agree-button,
body #sliding-popup #popup-buttons .button:not(.agree-button),
body
#sliding-popup
.popup-content
.eu-cookie-compliance-categories
+ #popup-buttons
.button:not(.agree-button) {
  font-weight: bold !important;
  background: #0e4dac !important;
  color: White !important;
  padding: 10px 18px !important; }

body #sliding-popup #popup-buttons .button:hover,
body #sliding-popup #popup-buttons .button.agree-button:hover,
body #sliding-popup #popup-buttons .button:not(.agree-button):hover,
body
#sliding-popup
.popup-content
.eu-cookie-compliance-categories
+ #popup-buttons
.button:not(.agree-button):hover {
  background: #0e4dac !important;
  color: White !important;
  filter: brightness(1.2); }

.embedded-video {
  margin-bottom: 1rem; }
  .embedded-video + .video-embed-mytube-description {
    margin-top: -0.6rem;
    margin-bottom: 1rem; }

.embedded-video .player,
div:not(.player) > .mytube-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 25px;
  height: 0;
  box-sizing: border-box; }
  .embedded-video .player *,
  div:not(.player) > .mytube-wrapper * {
    box-sizing: border-box; }
  .embedded-video .player iframe,
  .embedded-video .player .mytube,
  div:not(.player) > .mytube-wrapper iframe,
  div:not(.player) > .mytube-wrapper .mytube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .embedded-video .player .mytube,
  div:not(.player) > .mytube-wrapper .mytube {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .embedded-video .player .mytubethumb,
  div:not(.player) > .mytube-wrapper .mytubethumb {
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    max-width: none;
    margin: 0 !important; }
  .embedded-video .player .mytubeplay,
  div:not(.player) > .mytube-wrapper .mytubeplay {
    display: none !important; }
  .embedded-video .player .mytubetrigger,
  div:not(.player) > .mytube-wrapper .mytubetrigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%; }
    .embedded-video .player .mytubetrigger + iframe + .mytubetext,
    div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext {
      opacity: .5;
      border-radius: 0 0 0 3px;
      padding: .35em .5em;
      margin: 0 0 auto 0;
      align-self: flex-end;
      font-size: 10px;
      line-height: 1.5;
      max-width: 50%;
      max-height: 2.2em;
      overflow: hidden;
      text-align: right;
      hyphens: auto;
      word-break: break-all;
      background: rgba(255, 255, 255, 0.6); }
      .embedded-video .player .mytubetrigger + iframe + .mytubetext::before, .embedded-video .player .mytubetrigger + iframe + .mytubetext::after,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext::before,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext::after {
        display: none !important; }
      .embedded-video .player .mytubetrigger + iframe + .mytubetext:focus-within, .embedded-video .player .mytubetrigger + iframe + .mytubetext:hover,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext:focus-within,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext:hover {
        max-width: 95%;
        max-height: 3.6em;
        line-height: 1.1;
        word-break: normal; }
  .embedded-video .player .mytubetext,
  div:not(.player) > .mytube-wrapper .mytubetext {
    display: block;
    position: relative;
    z-index: 1;
    align-self: center;
    justify-content: center;
    margin: 92px 0 0 0;
    max-width: 90%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    padding: .65em;
    line-height: 1.1;
    font-size: 14px;
    border-radius: 3px;
    transition-duration: 150ms;
    transition-property: padding, background-color, bottom, max-width, max-height; }
    .embedded-video .player .mytubetext::before, .embedded-video .player .mytubetext::after,
    div:not(.player) > .mytube-wrapper .mytubetext::before,
    div:not(.player) > .mytube-wrapper .mytubetext::after {
      content: "";
      position: absolute;
      left: 50%;
      top: -92px;
      z-index: 3;
      margin-left: -37px;
      box-sizing: border-box;
      width: 64px;
      height: 74px;
      border-width: 37px 0px 37px 64px;
      border-style: solid;
      border-color: transparent transparent transparent rgba(255, 255, 255, 0.85);
      pointer-events: none; }
    .embedded-video .player .mytubetext:after,
    div:not(.player) > .mytube-wrapper .mytubetext:after {
      z-index: 2;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.35);
      filter: blur(3px); }
    .embedded-video .player .mytubetext:hover,
    div:not(.player) > .mytube-wrapper .mytubetext:hover {
      opacity: 1 !important;
      background: rgba(255, 255, 255, 0.9); }
      .embedded-video .player .mytubetext:hover::before,
      div:not(.player) > .mytube-wrapper .mytubetext:hover::before {
        border-color: transparent transparent transparent #fff; }
  .embedded-video .player .mytubeplay,
  div:not(.player) > .mytube-wrapper .mytubeplay {
    top: 50% !important;
    left: 50% !important;
    margin-left: -32px;
    margin-top: -30px; }
  .embedded-video .player:focus-within .mytubetext::before, .embedded-video .player:hover .mytubetext::before,
  div:not(.player) > .mytube-wrapper:focus-within .mytubetext::before,
  div:not(.player) > .mytube-wrapper:hover .mytubetext::before {
    border-color: transparent transparent transparent #fff !important; }
