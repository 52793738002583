// Styles for the views exposed filter calendar widget
.view-event-calendar{
  border:1px solid $grey_light;
  border-radius:0 0 $border_radius_small $border_radius_small;
  .view-header{
    background:$highlight;
    color:$highlight_text_color;
    margin:0;
    padding: ceil($thin_grid / 2) 0 0 0;
  }
  .date-nav-wrapper {
    .date-nav{
      padding-left:37px;
      padding-right:37px;
      position:relative;
      margin-bottom:0;
    }
    .date-prev,
    .date-next{
      position:absolute;
      top:4px;
      > a{
        height:30px;
        width:30px;
        text-indent:-9000px;
        line-height:13px;
        border-radius:50%;
        background:$highlight_dark;
        font-size:$font_small;
        &::before{
          font-family:$iconfont;
          text-indent:0;
          float:right;
          vertical-align:middle;
          text-align:center;
          width:100%;
        }
      }
    }
    .date-prev{
      left:5px;
      > a{
        &::before{
          @include get-ico-only('arrow-thin-left');
        }
      }

    }
    .date-next{
      right:5px;
      > a{
        &::before{
          @include get-ico-only('arrow-thin-right');
        }
      }
    }
  }
  .date-heading{
    text-align:center;

    h3{
      margin:0;
      padding:7px 0;
      font-size:$font_normal;
      font-family:$font_first;
      color:$highlight_text_color;
      a{
        color:$highlight_text_color;
      }
    }
    .pager{
      > li{
        background:none;
        padding:0;
        margin:0;
      }
    }
  }
  .view-content{
    table{
      width:100%;
      border-collapse:collapse;
      table-layout:fixed;
      line-height:1em;
      text-align:center;
    }
    thead{
      background:$highlight;
      color:$highlight_text_color;
      th{
        background:none;
        border:0 none;
        text-align:center;
        padding:8px 1px ceil($thin_grid / 2) 1px;
        white-space:nowrap;
        font-weight:$font_weight_normal;
        &:first-child{
          padding-left:ceil($thin_grid / 2);
        }
        &:last-child{
          padding-right:ceil($thin_grid / 2);
        }
      }
    }
    tbody{
      td{
        &:first-child{
          padding-left:ceil($thin_grid / 2);
        }
        &:last-child{
          padding-right:ceil($thin_grid / 2);
        }
      }
    }
    // Day
    .date-box{
      text-align:right;
    }
    // Multiday Event Marker Stripes
    .multi-day{
      .inner{
        margin-left:10px;
        margin-right:10px;
        position:relative;
      }
      .continuation{
        position:absolute;
        left:-10px;
        top:2px;
      }
      .continues{
        position:absolute;
        right:-10px;
        top:2px;
      }
    }
    .contents{
      a{
        background-color:$highlight_lighter;
        color:$highlight_text_color;
        display:block;
        text-align:center;
        padding:3px 5px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        font-size:$font_small;
        text-decoration:none;
        border-radius:$border_radius_small;
        margin-bottom:1px;
        transition-property: background-color;
        transition-duration: 250ms;
      }
      &:hover{
        a{
          background:$highlight_sec;
        }
      }
    }

    // Mini Calendar
    table.mini{
      table-layout:initial;
      width:100%;
      .calendar-empty{ // whatever this is.. hide it.
        display:none;
      }
      tbody{
        tr{
          &:first-child{
            border-top:5px solid white;
          }
          &:last-child{
            border-bottom:5px solid white;
          }
        }
      }
      td{
        line-height:1em;
        a{
          display:inline-block;
          width:30px;
          height:30px;
          line-height:30px;
          text-align:center;
          border-radius:50%;
          color:$grey;
          text-decoration:none;
        }
        &.empty{
          a{
            color:$grey_light;
          }
        }
        &.has-no-events{
          a{
            color:$grey;
          }
        }
        &.has-events{
          a{
            color:$highlight;
            &:hover{
              background-color:$highlight;
              color:$highlight_text_color;
            }
          }
        }
        &.today{
          a{
            background-color:$highlight_lighter;
            color:$highlight_text_color;
          }
        }
      }
    }
  }
}
