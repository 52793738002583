
// =========================================
// Mytube cookie compliance for embed videos
// =========================================

$white-glass: rgba(255,255,255,.85) !default;

.embedded-video{
  margin-bottom: 1rem;
  + .video-embed-mytube-description{
    margin-top: - .6rem;
    margin-bottom:1rem;
  }
}
.embedded-video .player,
div:not(.player) > .mytube-wrapper {
  position: relative;
  padding-bottom: 51.5%; // reduced from 56.25% /* 16:9 */
  padding-top: 25px;
  height: 0;
  box-sizing: border-box;
  *{
    box-sizing: border-box;
  }
  iframe,
  .mytube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .mytube{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  .mytubethumb{
    object-fit:cover;
    width:100% !important;
    height:100% !important;
    max-width:none;
    margin:0 !important;
  }
  .mytubeplay{
    // Hide default play button ugly and bad positioned in markup
    display:none !important;
  }
  .mytubetrigger{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100%;
    + iframe + .mytubetext{
      // youtube iframe loaded
      opacity:.5;
      border-radius: 0 0 0 3px;
      padding:.35em .5em;
      margin: 0 0 auto 0;
      align-self: flex-end;
      font-size:10px;
      line-height: 1.5;
      max-width:50%;
      max-height: 2.2em;
      overflow:hidden;
      text-align:right;
      hyphens: auto;
      word-break: break-all;
      // Shorten the text is a bad idea here - we would possibly hide important links or something
      // text-overflow:ellipsis;
      // white-space: nowrap;
      background: rgba(255,255,255,.6);
      &::before,
      &::after{
        display:none !important;
      }
      &:focus-within,
      &:hover{
        max-width:95%;
        max-height:3.6em;
        line-height: 1.1;
        word-break:normal;
      }
    }
  }
  .mytubetext{
    display:block;
    position: relative;
    z-index:1;
    align-self:center;
    justify-content: center;
    margin:92px 0 0 0;
    max-width:90%;
    box-sizing: border-box;
    background: rgba(255,255,255,.8);
    padding: .65em;
    line-height: 1.1;
    font-size:$medium-font-size;
    border-radius: 3px;
    transition-duration:150ms;
    transition-property: padding, background-color, bottom, max-width, max-height;
    &::before,
    &::after{
      content:"";
      position:absolute;
      left:50%;
      top:-92px;
      z-index:3;
      margin-left:-37px;
      box-sizing: border-box;
      width: 64px;
      height: 74px;
      border-width: 37px 0px 37px 64px;
      border-style:solid;
      border-color: transparent transparent transparent $white-glass;
      pointer-events:none;
    }
    &:after{
      z-index:2;
      border-color: transparent transparent transparent rgba(0,0,0,.35);
      filter: blur(3px);
    }
    &:hover{
      opacity:1 !important;
      background: rgba(255,255,255,.9);
      &::before{
        border-color: transparent transparent transparent $white;
      }
    }
  }
  .mytubeplay{
    top:50% !important;
    left:50% !important;
    margin-left: -32px;
    margin-top: -30px;
  }
  &:focus-within,
  &:hover{
    .mytubetext::before{
      border-color: transparent transparent transparent $white !important;
    }
  }
}
