$enable-flex: false !default; // switch from a floating grid system to a flex grid system

// ***********************************************
// Layout, Metrics (and responsiveness)
// ***********************************************
$grid_columns: 12;

$space_thin_v: 15px;
$space_thin_h: 15px;
$space_medium_v: 25px;
$space_medium_h: 25px;
$space_large_v: 45px;
$space_large_h: 45px;

$grid: 43px;
$grid-gutter-width: $grid;
$half_grid: ceil($grid / 2);
$thin_grid: $space_thin_h;
$grid_vertical: $space_medium_v;
$mobile_grid: 20px;
$page_outer_grid_mobile: 15px;
$page_outer_grid: 30px;
$page_max_width: 1140px;
$page_max_width_tile_pages: $page_max_width; // deprecated variable?

$sidebar_first_width: 180px + $grid;
$sidebar_second_width: 220px + $grid;

// Tile Grid & Widths
$tileWidth: 357px;
$tileGridSpace:15px;
$tileBreakpointOffset:20px;

// View Image (Image Column)
$view_image_width: 120px;

// ***********************************************
// Breakpoints
// ***********************************************

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$small_breakpoint_value:  544px;
$medium_breakpoint_value: 768px;
$large_breakpoint_value:  992px;
$xlarge_breakpoint_value: $page_max_width;
$xxlarge_breakpoint_value: 1420px;


$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: $small_breakpoint_value,
  // Medium screen / tablet
  md: $medium_breakpoint_value,
  // Large screen / desktop
  lg: $large_breakpoint_value,
  // Extra large screen / wide desktop
  xl: $xlarge_breakpoint_value
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// TODO: Containers currently not implemented in our theme ..

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;


// Further custom Breakpoint definitions

@if ($layout_tile_grid_active == true){
  // Tile layout in use
  $small_breakpoint_value:  $tileWidth + $tileBreakpointOffset  !default;
  $medium_breakpoint_value: ($tileWidth * 2) + $tileGridSpace + $tileBreakpointOffset !default;
  $large_breakpoint_value:  ($tileWidth * 3) + ($tileGridSpace * 2) + $tileBreakpointOffset !default;
  $xlarge_breakpoint_value: ($tileWidth * 4) + ($tileGridSpace * 3) + $tileBreakpointOffset !default;
  $xxlarge_breakpoint_value: ($tileWidth * 5) + ($tileGridSpace * 4) + $tileBreakpointOffset !default;
}


$small_min_breakpoint:  $small_breakpoint_value !default;
$medium_min_breakpoint: $medium_breakpoint_value !default;
$large_min_breakpoint:  $large_breakpoint_value !default;
$xlarge_min_breakpoint: $xlarge_breakpoint_value !default;
$xxlarge_min_breakpoint: $xxlarge_breakpoint_value !default;

$small_max_breakpoint:  $medium_breakpoint_value - 1px  !default;
$medium_max_breakpoint: $large_breakpoint_value - 1px !default;
$large_max_breakpoint:  $xlarge_breakpoint_value - 1px !default;
$xlarge_max_breakpoint:  $xxlarge_breakpoint_value - 1px !default;

// Media Queries
$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small_up: "#{$screen} and (min-width:#{$small_min_breakpoint})" !default;
$small_only: "#{$screen} and (max-width: #{$small_max_breakpoint})" !default;

$medium_up: "#{$screen} and (min-width:#{$medium_min_breakpoint})" !default;
$medium_down: "#{$screen} and (max-width:#{$medium_max_breakpoint})" !default;
$medium_only: "#{$screen} and (min-width:#{$medium_min_breakpoint}) and (max-width:#{$medium_max_breakpoint})" !default;

$large_up: "#{$screen} and (min-width:#{$large_min_breakpoint})" !default;
$large_down: "#{$screen} and (max-width:#{$large_max_breakpoint})" !default;
$large_only: "#{$screen} and (min-width:#{$large_min_breakpoint}) and (max-width:#{$large_max_breakpoint})" !default;

$xlarge_up: "#{$screen} and (min-width:#{$xlarge_min_breakpoint})" !default;
$xlarge_down: "#{$screen} and (max-width:#{$xlarge_max_breakpoint})" !default;
$xlarge_only: "#{$screen} and (min-width:#{$xlarge_min_breakpoint}) and (max-width:#{$xlarge_max_breakpoint})" !default;

$xxlarge_up: "#{$screen} and (min-width:#{$xxlarge_min_breakpoint})" !default;
// does not make sens on the largest mq $xlarge_down: "#{$screen} and (max-width:#{$xlarge_max_breakpoint})" !default;
// does not make sens on the largest mq $xlarge_only: "#{$screen} and (min-width:#{$xlarge_min_breakpoint}) and (max-width:#{$xlarge_max_breakpoint})" !default;

// $xxlarge_up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
// $xxlarge_only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// -- This one is a bit special. But it simply says "Its width enough to float elements inside content region"
// -- So it fits the following viewport states: large screens with sidebar & Medium screens without sidbar (sidebar already moved to bottom)
// -- TODO: This needs some testing, may not work as expected.
$enough_width: "#{$screen} and (min-width:#{$medium_min_breakpoint})" !default;
